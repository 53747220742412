import { cloneElement, FC, isValidElement, Key, ReactNode, useContext, useEffect } from "react";

import { BoxProps } from "@xcorejs/ui";
import { AccordionContext, AccordionItemContext, isExpanded } from "./data";

interface Props {
  expanded?: boolean;
  index?: Key;
  children: ReactNode | ((expanded: boolean, index: Key) => ReactNode);
}

const propagate = <T extends unknown>(child: ReactNode, props: T) =>
  isValidElement(child) ? cloneElement(child, props) : child;

const AccordionItem: FC<Props & BoxProps> = ({ children, expanded, index, ...box }) => {
  const accordion = useContext(AccordionContext);

  useEffect(() => {
    accordion.refs && !accordion.refs.current.includes(index!) && accordion.refs.current.push(index!);
    return () => {
      accordion.refs && (accordion.refs.current = accordion.refs.current.filter(k => k !== index));
    };
  });

  return (
    <AccordionItemContext.Provider value={{ index, expanded }}>
      {propagate(
        typeof children === "function"
          ? (children as any)(isExpanded(expanded!, accordion.expanded, index!), index!)
          : children,
        box
      )}
    </AccordionItemContext.Provider>
  );
};

export default AccordionItem;
