import { useLink, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Flex, Link, Stack, Typography, useModal } from "@xcorejs/ui";
import { ErrorAnimatedIcon, SuccessAnimatedIcon } from "components/icons/AnimatedIcon";
import { userLogout } from "components/Layout/LoggedBar";
import LostPasswordModal from "components/LostPasswordModal";
import Checkbox from "design-system/xcore/Form/Checkbox";
import FormControl from "design-system/xcore/Form/FormControl";
import Input from "design-system/xcore/Form/Input";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { swrFetcher } from "pages/_app";
import React, { FC, useState } from "react";
import { useUser } from "utils/useUser";
import { useLayout } from "xcore";
import { bool, object, string } from "yup";

import { useRevalidateCache } from "../pages/_app";
import { ActivationPage, UserLoginPage } from "../xcore/types";
import RobeLoader from "./RobeLoader";
import RobeRichtext from "./RobeRichtext";

const UserLoginComponent: FC = () => {
  const { stringify, general, domain, cms, localize } = useLayout();
  const [lostPassword] = useModal(LostPasswordModal);
  const [close] = useModal();
  const { data } = useXcoreQuery(c => c.content.single<UserLoginPage>("userLoginPage"));
  const { data: activationPage } = useXcoreQuery(c => c.content.single<ActivationPage>("activationPage"));
  const { user: loginData } = useUser();
  const { as } = useLink();
  const [username, setUsername] = useState("");
  const [resendState, setResendState] = useState<undefined | "success" | "error">(undefined);
  const [sent, setSent] = useState(false);
  const revalidate = useRevalidateCache();
  const [keepLogin, setKeepLogin] = useState<boolean>(false);

  const userLoginSchema = object().shape({
    username: string().required(stringify(general.values.requiredField)),
    password: string().required(stringify(general.values.requiredField)),
    remember: bool()
  });

  const userLogin = async (userLoginValues: UserLoginForm, helpers: FormikHelpers<UserLoginForm>) => {
    const response = await swrFetcher(cms.apiUrl)("/api/web/login", {
      method: "POST",
      body: JSON.stringify(userLoginValues)
    }, false);

    if (!response.ok) {
      const { status } = await response.json();
      const errors: any = {};

      if (status === "unknown") errors.username = ["??? Unknown error ???"];
      if (status === "usernameNotFound") errors.username = [stringify(general.values.userNameNF)];
      if (status === "wrongPassword") errors.password = [stringify(general.values.wrongPass)];
      if (status === "userIsNotActivated") errors.active = [stringify(general.values.userNotActivated)];

      helpers.setErrors(errors);
      return;
    }

    helpers.setSubmitting(false);
    setSent(true);
    setTimeout(() => {
      setSent(false);
      close();
    }, 3000);
    revalidate();
  };

  const resendActivation = async () => {
    const response = await fetch("/api/web/user/confirmation/resend", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: username,
        url: "https://" + as(activationPage?.content, { domain }).substring(2)
      })
    });

    if (!response.ok) {
      setResendState("error");
      setTimeout(() => setResendState(undefined), 3000);
      return;
    }

    setResendState("success");
    setTimeout(() => setResendState(undefined), 3000);
  };

  return (
    data ? (
      <>
        <Typography variant="h3" as="div" textAlign="center">
          {stringify(data.content.values.userLoginHeading)}
        </Typography>

        {sent ? (
          <>
            {localize(data.content.values.userLoginIntro) && (
              <Box mt="1rem">
                <RobeRichtext value={data.content.values.userLoginIntro} _paragraph={{ textAlign: "center" }} />
              </Box>
            )}
            <Flex flexDirection="column" alignItems="center" mt="3rem">
              <SuccessAnimatedIcon />
            </Flex>
          </>
        )
          : loginData ? (
            <Flex alignItems="center" flexDirection="column">
              {stringify(data.content.values.userLoginLogoutText) && (
                <Typography mt="1rem">
                  {stringify(data.content.values.userLoginLogoutText)}
                </Typography>
              )}

              <Button mt="3rem" onClick={userLogout(cms, revalidate)}>
                {stringify(data.content.values.userLoginLogout)}
              </Button>
            </Flex>
          ) : (
            <>
              {localize(data.content.values.userLoginIntro) && (
                <Box mt="1rem">
                  <RobeRichtext value={data.content.values.userLoginIntro} _paragraph={{ textAlign: "center" }} />
                </Box>
              )}
              <Box width="100%" maxWidth="41rem" mx="auto" mt="3rem">
                <Formik
                  initialValues={{ username: "", password: "", remember: false }}
                  onSubmit={userLogin}
                  validationSchema={userLoginSchema}
                >
                  {helpers => (
                    <Form>
                      <Stack direction="column" gap="3rem" wrapItems>
                        <Field name="username">
                          {({ field, meta }: FieldProps) => (
                            <FormControl
                              labelText={stringify(data.content.values.userLoginUsernameLabel)}
                              context="isRequired"
                              errorContext={meta.error && meta.touched ? "isInvalid" : "normal"}
                              error={meta.touched && meta.error}
                              width="100%"
                            >
                              <Input inputSize="md" width="100%" type="text" onKeyUp={(e) => setUsername(e.target.value)} {...field} />
                            </FormControl>
                          )}
                        </Field>
                        <Box>
                          <Field name="password">
                            {({ field, meta }: FieldProps) => (
                              <FormControl
                                labelText={stringify(data.content.values.userLoginPasswordLabel)}
                                context="isRequired"
                                errorContext={meta.error && meta.touched ? "isInvalid" : "normal"}
                                error={meta.touched && meta.error}
                                width="100%"
                              >
                                <Input inputSize="md" width="100%" type="password" {...field} />
                              </FormControl>
                            )}
                          </Field>
                          <Flex mt="2rem" justifyContent="center">
                            <Link variant="underline" onClick={lostPassword}>
                              {stringify(data.content.values.userLoginForgottenPass)}
                            </Link>
                          </Flex>
                        </Box>

                        <Checkbox
                          checked={keepLogin}
                          onChange={(e: any) => {
                            setKeepLogin(p => !p);
                            helpers.setFieldValue("remember", e.target.checked);
                          }}
                        >
                          <Typography>{stringify(data.content.values.preserveLogin)}</Typography>
                        </Checkbox>

                        {Object.entries(helpers.errors)
                          .filter((e) => e[0] !== "password" && e[0] !== "username" && e[0] !== "active").map(([_, errors], i) => (
                            <Typography
                              key={i}
                              color="robe"
                              textAlign="center"
                              fontWeight="bold"
                            >
                              {errors}
                            </Typography>
                          ))}

                        {Object.entries(helpers.errors).length === 1 && (helpers.errors as any).active && (
                          <>
                            <Typography
                              color="robe"
                              textAlign="center"
                              fontWeight="bold"
                            >
                              {(helpers.errors as any).active}
                            </Typography>
                            <Flex justifyContent="center" mt="1rem">
                              {resendState === "success"
                                ? <SuccessAnimatedIcon />
                                : resendState === "error"
                                  ? <ErrorAnimatedIcon />
                                  : (
                                    <Link variant="underline" fontWeight={400} onClick={() => resendActivation()}>
                                      {stringify(data.content.values.userLoginResendActivation)}
                                    </Link>
                                  )}
                            </Flex>
                          </>
                        )}
                        <Flex justifyContent="center">
                          <Button display="flex" type="submit">
                            {stringify(data.content.values.userLoginButton)}
                          </Button>
                        </Flex>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </>
          )}
      </>
    ) : (
      <Flex justifyContent="center" alignItems="center" width="100%">
        <RobeLoader />
      </Flex>
    )
  );
};

export default UserLoginComponent;

interface UserLoginForm {
  username: string;
  password: string;
  remember: boolean;
}
