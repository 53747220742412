import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Content } from "@appiodev/xcore-core";
import {
  Button,
  ExtendedButtonProps,
  ExtendedLinkProps,
  ExtendedTextProps,
  IconProps,
  Link as UILink,
  Modal,
  Text,
  useModal
} from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import { FC } from "react";
import { useLayout } from "xcore";
import { SmartLink as SmartLinkType } from "xcore/types";
import { useLocalizedRelation } from "../utils/useLocalizedRelation";

export interface SmartLinkProps {
  _link?: ExtendedLinkProps;
  _text?: ExtendedTextProps;
  _iconProps?: IconProps;
  asButton?: boolean;
  _buttonProps?: ExtendedButtonProps;

  smartLink: SmartLinkType;
}

const SmartLink: FC<SmartLinkProps> = (props) => {
  const { smartLink: { values }, _link, _text, _iconProps, asButton, _buttonProps } = props;
  const { file, stringify } = useLayout();
  const hasRelation = !!useLocalizedRelation("relation", values, undefined);
  const hasNoLinks = !hasRelation && !stringify(values.embedded) && !stringify(values.externalUrl);

  if (!values) return null;

  if (hasNoLinks) {
    return (
      <Text {..._text}>Invalid link</Text>
    );
  }

  const type = hasRelation
    ? "relation"
    : stringify(values.externalUrl)
      ? "url"
      : "embeded";

  const text = () =>
    asButton ? (
      <Button
        leftIcon={values.icon && (
          <ExternalSVG
            src={file(values.icon)}
            mr="1rem"
            fill="#fff"
            display="flex"
            width="1.6rem"
            minWidth="1.6rem"
            height="1.6rem"
            _group={{ _hover: { _icon: { fill: "#fff" } } }}
            {..._iconProps}
          />
        )}
        {..._buttonProps}
      >
        {stringify(values.name)}
      </Button>
    ) : (
      <>
        {values.icon && (
          <ExternalSVG
            src={file(values.icon)}
            mr="1rem"
            fill="gray"
            display="flex"
            width="1.6rem"
            minWidth="1.6rem"
            height="1.6rem"
            _group={{ _hover: { _icon: { fill: "#fff" } } }}
            {..._iconProps}
          />
        )}
        <Text {..._text}>{stringify(values.name)}</Text>
      </>
    )
  ;

  return (
    <>
      {type === "relation"
        ? <RelationLink {...props}>{text()}</RelationLink>
        : type === "url"
          ? <UrlLink {...props}>{text()}</UrlLink>
          : <EmbeddedLink {...props}>{text()}</EmbeddedLink>}
    </>
  );
};

export default SmartLink;

const RelationLink: FC<SmartLinkProps> = ({ smartLink: { values }, _link, children }) => {
  const { localize } = useLayout();
  const content = useLocalizedRelation("relation", values, undefined as Content | undefined);

  return (
    <Link type="simple" content={content!} target={localize(values.newWindow) && "_blank"} role="group" {..._link}>
      {children}
    </Link>
  );
};

const UrlLink: FC<SmartLinkProps> = ({ smartLink: { values }, _link, children }) => {
  const { stringify, localize } = useLayout();
  return (
    <UILink href={stringify(values.externalUrl)} target={localize(values.newWindow) && "_blank"} type="simple" role="group" {..._link}>
      {children}
    </UILink>
  );
};

const EmbeddedLink: FC<SmartLinkProps> = ({ smartLink: { values }, _link, children }) => {
  const { stringify } = useLayout();
  const [open] = useModal(EmbeddedModal, { html: stringify(values.embedded) }); // TODO custom modal

  return (
    <UILink as="span" type="simple" onClick={open} role="group" {..._link}>
      {children}
    </UILink>
  );
};

interface EmbeddedModalProps {
  html: string;
}

const EmbeddedModal: FC<EmbeddedModalProps> = ({ html }) => {
  return (
    <Modal
      size="lg"
      _overlay={{
        zIndex: 5
      }}
      p="0 !important"
      _close={{ zIndex: 5 }}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Modal>
  );
};
