import { Link, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, BoxProps, Flex, Icon, Img, Typography, Link as UILink } from "@xcorejs/ui";
import BurgerIcon from "components/icons/24/burger.svg";
import CloseIcon from "components/icons/24/close.svg";
import SearchIcon from "components/icons/24/search.svg";
import CloseIconSmall from "components/icons/8/close.svg";
import User from "components/icons/user.svg";
import Navigation from "design-system/appio/navigation";
import Cookies from "js-cookie";
import { Router, useRouter } from "next/router";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useLayout } from "xcore";

import { useUser } from "utils/useUser";
import DomainSwitcher from "./DomainSwitcher";
import { HeaderMiddleContent } from "./HeaderMiddleContent";
import MobileDomainSwitcher from "./MobileDomainSwitcher";
import { ScrollBarBoxStyle } from "./NavItem/NavItemDropdown";
import SearchField from "./SearchField";

export type HeaderState = "menu" | "search" | "localeSwitcher" | null;

const Header: FC = () => {
  const { user } = useUser();
  const isLogged = !!user;

  const [state, setState] = useState<HeaderState>(null);
  const close = useCallback(() => setState(null), []);

  const [content] = usePageContent();
  const { header: { nav }, config } = useLayout();
  const { isPreview } = useRouter();

  const env = config.apiUrl.includes("robe.wnh.cz") ? "Dev" : config.apiUrl.includes("test") ? "Test" : undefined;

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");

    const h2 = () => document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
    window.addEventListener("resize", h2);

    const h1 = () => setState(null);
    Router.events.on("routeChangeComplete", h1);

    return () => {
      Router.events.off("routeChangeComplete", h1);
      window.removeEventListener("resize", h2);
    };
  }, []);

  return (
    <>
      <Navigation
        leftContent={(
          <Flex>
            <Link
              content={nav.homepage}
              type="simple"
              width={{ _: "10rem", xxs: "12rem", md: "12rem", lg: "16rem" }}
              alignItems="center"
              flex="0 0 auto"
            >
              <Img src="/static/robe-black.svg" alt="" width="100%" loading="lazy" />
              {env && <Typography m="4rem 0 0 .4rem" fontSize="14px">{env}</Typography>}
            </Link>
            <MobileDomainSwitcher
              open={state === "localeSwitcher"}
              setState={o => setState(o ? "localeSwitcher" : null)}
            />
            <Box display={{ _: "flex", md: "none" }} m="4.5rem 0 0 -13.7rem">
              <RegionInfo />
            </Box>
          </Flex>
        )}
        middleContent={<HeaderMiddleContent state={state} />}
        rightContent={(
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            gridColumn={{ _: "2/4", md: "3/4" }}
          >
            <UILink
              type="simple"
              onClick={() => setState("search")}
              pl={{ _: "1.8rem", md: 0 }}
              borderLeft={{ _: "1px solid lightGray", md: "none" }}
              height="100%"
              alignItems="center"
            >
              <Icon
                svg={state === "search" ? <CloseIcon /> : <SearchIcon />}
                fill={content.type === "search" ? "robe" : "gray"}
                fillHover="black"
              />
            </UILink>

            <Box ml={{ _: "1.8rem", sm: "1.8rem", lg: "2rem" }} height={{ _: "100%", md: "3.2rem" }} width="1px" bg="lightGray" />

            <Flex flexDirection="column" position="relative">
              <DomainSwitcher />
              <RegionInfo display={{ _: "none", md: "flex" }} />
            </Flex>

            <Box
              onClick={() => setState(s => s === "menu" ? null : "menu")}
            >
              {isLogged && state !== "menu" && (
                <Box
                  display={{ _: "flex", sm: "none" }}
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  border="none"
                  top={12}
                  right={9}
                >
                  <User />
                </Box>
              )}
              <Icon
                svg={state === "menu" ? <CloseIcon /> : <BurgerIcon />}
                fill="gray"
                fillHover="black"
                ml={{ _: "1.8rem", lg: "2rem" }}
                display={{ _: "block", md: "none" }}
              />
            </Box>
            <SearchField
              open={state === "search"}
              close={close}
            />
          </Flex>
        )}
        _container={{ justifyContent: "space-between", alignItems: "center", position: state === "search" ? "relative" : "static" }}
        _grid={{ columns: { _: "1fr auto 1fr", md: "13rem 1fr auto", lg: "17rem 1fr auto" }, rows: "auto" }}
        position="sticky"
        top={{ _: 0, sm: `${3.2 * (+isLogged + +isPreview)}rem` }}
        zIndex={1003}
        height={{ _: "6rem", md: "9rem" }}
        boxShadow={{ _: "0 0 1rem 0 rgba(30, 30, 30, 0.25)", md: "none" }}
        borderBottom={{ _: "1px solid lightGray", md: "none" }}
      />
    </>
  );
};
export default memo(Header);

const RegionInfo: FC<BoxProps> = (props) => {
  const { stringify, general } = useLayout();
  const [close, setClose] = useState<boolean>(false);
  const isCookie = Cookies.get("multiple-visit") === "1";
  const [windowWidth, setWindowWidth] = useState<number | null>(null);

  useEffect(() => {
    const h = () => setWindowWidth(window.innerWidth);
    h();
    window.addEventListener("resize", h);
    return () => window.removeEventListener("resize", h);
  }, []);

  if (close || isCookie || !windowWidth) return null;
  return (
    <ScrollBarBoxStyle
      bg="#e7e7e7"
      position="absolute"
      boxShadow="0 0.3rem 0.5rem 0 rgba(30, 30, 30, 0.5)"
      width="25rem"
      mt={{ _: "1.5rem", md: "4rem" }}
      right={{ md: windowWidth > 1024 && windowWidth < 1427 ? "1%" : "-6.8rem" }}
      display={close ? "none" : props.display}
      cursor="auto"
      _hover={{ boxShadow: "0 0.8rem 0.8rem 0 rgba(30, 30, 30, 0.5)" }}
    >
      <Box
        height="2rem"
        width="2rem"
        bg="#e7e7e7"
        position="absolute"
        top="-1rem"
        right={windowWidth > 1024 && windowWidth < 1427 ? "20%" : "50%"}
        transform="rotate(45deg)"
      />
      <Box position="absolute" right="0" top="0">
        <UILink
          type="simple"
          v="simple"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            setClose(true);
            Cookies.set("multiple-visit", "1", { expires: 365 * 2 });
          }}
        >
          <Icon
            m="1.3rem"
            svg={<CloseIconSmall />}
            fill="#747474"
            fillHover="black"
          />
        </UILink>
      </Box>
      <Typography
        fontSize="1.4rem"
        lineHeight="2.5rem"
        overflow="hidden"
        textAlign="center"
        p="3.2rem 2rem 2rem 2rem"
      >
        {stringify(general.values.regionInfoBubble)}
      </Typography>
    </ScrollBarBoxStyle>
  );
};
