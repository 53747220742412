import AccordionItem from "design-system/xcore/Accordion/AccordionItem";
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  Key,
  ReactElement,
  useState,
  MutableRefObject
} from "react";

import { AccordionContext } from "./data";
import { BoxProps } from "@xcorejs/ui";

export type AccordionProps = {
  onSelect?: any;
  refs?: MutableRefObject<Key[]>;
} & (
  | ({
    multiple: true;
    toggle?: undefined;
  } & ({ controlled: true; expanded: Key[] } | { controlled?: false; expanded?: Key[] }))
  | ({
    multiple?: false;
    toggle?: boolean;
    expanded?: Key;
  } & ({ controlled: true; expanded: Key } | { controlled?: false; expanded?: Key }))
);

const Accordion: FC<AccordionProps & BoxProps> = ({
  multiple,
  expanded,
  controlled,
  children,
  onSelect,
  toggle,
  refs,
  ...box
}) => {
  const [expandedState, setExpandedState] = useState(multiple ? expanded || [] : expanded || null);
  const setState = (key: Key) =>
    setExpandedState(
      multiple
        ? (expandedState as Key[]).includes(key)
          ? (expandedState as Key[]).filter(k => k !== key)
          : [...(expandedState as Key[]), key]
        : toggle && key === expandedState
          ? null
          : key
    );

  return (
    <AccordionContext.Provider
      value={{
        expanded: controlled ? expanded : expandedState,
        onSelect: (key: Key) => controlled ? onSelect?.(key) : setState(key),
        refs
      }}
    >
      {Children.map(children, (child, index) =>
        isValidElement(child)
          ? (child as ReactElement).type === AccordionItem
            ? cloneElement(child, { ...box, index: (child as ReactElement).key || index } as any)
            : cloneElement(child, { ...box })
          : child
      )}
    </AccordionContext.Provider>
  );
};

export default Accordion;
