import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { useRouter } from "next/router";
import { useLayout } from "xcore";

export const hiddenDomains = [
  "www.robeuk.com",
  "www.robelighting.es",
  "www.robelighting.it",
  "robe-asia.wnh.cz",
  // "www.robe.ru"
  "www.roberussia.com"
];

export const domainLabels = {
  "en": "Global",
  "de": "Germany",
  "ru": "Russia",
  "en-NA": "North America",
  "cs": "Czechia",
  "fr": "France",
  "en-SEA": "South-east Asia"
};

export const useDomainSwitcher = () => {
  const { locale } = useRouter();
  const { resolvedDomains } = useLayout();
  const [content] = usePageContent();

  const getLabel = (l: string) => domainLabels[l];

  return {
    dropdownLinkProps: Object.entries(resolvedDomains)
      .filter(([d]) => !hiddenDomains.includes(d))
      .map(([domain, l]) => ({
        content,
        domain,
        locale: l,
        label: getLabel(l)
      })),
    label: getLabel(locale)
  };
};
