import { useLink, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Flex, Link, Typography, useModal } from "@xcorejs/ui";
import { SuccessAnimatedIcon } from "components/icons/AnimatedIcon";
import RobeLoader from "components/RobeLoader";
import UserLoginModal from "components/UserLoginModal";
import FormControl from "design-system/xcore/Form/FormControl";
import Input from "design-system/xcore/Form/Input";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { FC, useState } from "react";
import { useLayout } from "xcore";
import { ForgottenPassword } from "xcore/types";
import { object, string } from "yup";
import { useRouter } from "next/router";

const resetPasswordSchema = object().shape({
  email: string().email().required()
});

const LostPasswordComponent: FC = () => {
  const { stringify, newPassPage, cms: { apiUrl } } = useLayout();
  const [userLogin] = useModal(UserLoginModal);
  const { data } = useXcoreQuery(cms => cms.content.single<ForgottenPassword>("forgottenPassPage"));
  const [sent, setSent] = useState(false);

  const { domain, general } = useLayout();
  const { as } = useLink();
  const { locale } = useRouter();

  const resetPassword = async (resetValues: ResetPasswordForm, helpers: FormikHelpers<ResetPasswordForm>) => {
    const response = await fetch(`${apiUrl}/api/web/lost-password?locale=${locale}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...resetValues,
        url: "https://" + as(newPassPage, { domain }).substring(2)
      })
    });

    if (!response.ok) {
      const c = await response.json();
      helpers.setErrors(c.errors);
      return;
    }

    helpers.setSubmitting(false);
    setSent(true);
  };

  return (
    data ? (
      <>
        <Typography variant="h3" as="div" textAlign="center">
          {stringify(data.content.values.forgotPassHeading)}
        </Typography>

        {stringify(data.content.values.forgotPassIntro) && (
          <Typography mt="1rem" textAlign="center">
            {stringify(data.content.values.forgotPassIntro)}
          </Typography>
        )}

        <Box width="100%" maxWidth="41rem" mx="auto" mt="3rem">
          {sent ? (
            <Flex flexDirection="column" alignItems="center">
              <SuccessAnimatedIcon />

              <Typography mt="2rem" textAlign="center">
                {stringify(data.content.values.successMsg)}
              </Typography>
            </Flex>
          ) : (
            <Formik
              initialValues={{ email: "" }}
              onSubmit={resetPassword}
              validationSchema={resetPasswordSchema}
            >
              <Form>
                <Field name="email">
                  {({ field, meta }: FieldProps) => (
                    <FormControl
                      labelText={stringify(data.content.values.forgotPassLabel)}
                      context="isRequired"
                      errorContext={meta.error && meta.touched ? "isInvalid" : "normal"}
                      error={meta.touched && meta.error?.[0] === "E-mail not found." ? stringify(general.values.emailNotFound) : meta.error}
                      width="100%"
                    >
                      <Input inputSize="md" width="100%" {...field} />
                    </FormControl>
                  )}
                </Field>

                <Flex mt="2rem" justifyContent="center">
                  <Link justifyContent="center" variant="underline" onClick={userLogin}>
                    {stringify(data.content.values.forgotPassBackLink)}
                  </Link>
                </Flex>

                <Flex justifyContent="center" mt={{ _: "2rem", sm: "3rem" }}>
                  <Button>
                    {stringify(data.content.values.forgotPassResetButton)}
                  </Button>
                </Flex>
              </Form>
            </Formik>
          )}
        </Box>
      </>
    ) : (
      <Flex justifyContent="center" alignItems="center" width="100%">
        <RobeLoader />
      </Flex>
    )
  );
};

export default LostPasswordComponent;

interface ResetPasswordForm {
  email: string;
}
