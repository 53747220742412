import { Relation } from "@appiodev/xcore-core";
import { useMemo } from "react";
import { resolveLocaleField, useLocalizedRelation } from "utils/useLocalizedRelation";
import { useLayout } from "xcore";
import { Accessory, Product, ProductOverviewPage, Products } from "xcore/types";

import NavItem, { NavItemProps } from "./NavItem";

export const useHeaderMenu = (productsData: Products) => {
  const { header: { nav }, general, stringify, title, cms, value } = useLayout();

  type MenuItem = Parameters<typeof NavItem>[0];

  // Homepage
  const homepage = useMemo<MenuItem>(() => ({
    content: nav.homepage,
    activeOn: ["homepage"],
    children: title(nav.homepage)
  }), [nav.homepage, title]);

  // Spot On
  const spotOnField = useLocalizedRelation("itemsRelation", nav.spotOn.values, undefined as Relation[] | undefined);

  const spotOn = useMemo<MenuItem>(() => ({
    activeOn: ["spotOn", "generalSpotOn", "videoSpotOn"],
    dropdown: [...spotOnField?.map(r => [{ children: title(r), content: r }, []])] as [NavItemProps, NavItemProps[]][],
    children: title(nav.spotOn)
  }), [nav.spotOn, spotOnField, title]);

  // Products
  const productItems = useLocalizedRelation(
    "itemsRelation",
    productsData.values ?? ({} as Partial<Products>["values"]),
    undefined as Relation<Accessory | Product | ProductOverviewPage>[] | undefined
  );

  const products = useMemo<MenuItem>(() => ({
    content: productsData,
    activeOn: [
      "products",
      "product",
      "productTypePage",
      "applicationPage",
      "beamTypePage",
      "productOverviewPage",
      "productFamilyPage"
    ],
    overviewLink: {
      content: productsData,
      children: stringify(general.values.productOverviewButton)
    },
    children: stringify(productsData?.title),
    dropdown: productItems && [
      ...productItems?.map(p => [
        { children: title(p), content: p },
        resolveLocaleField(
          "itemsRelation",
          // as any is here because p.values does not always have itemsRelation field and it is just resolved to undefined
          value(p.values) as any ?? {},
          undefined as Relation[] | undefined,
          cms.locales.content
        )?.map(x => ({ children: stringify(x.title), content: x })) ?? []
      ] as any) ?? []
    ]
  }), [productsData, stringify, general.values.productOverviewButton, productItems, title, value, cms.locales.content]);

  // Innovations
  const innovations = useMemo<MenuItem>(() => ({
    content: nav.innovations,
    activeOn: ["innovation", "innovations"],
    // dropdown: [
    //   [{
    //     content: nav.innovations,
    //     children: title(nav.innovations) + " " + stringify(general.values.overviewBtn),
    //     overviewLink: true
    //   }, []],
    //   ...nav.innovations.values.activeInnovations.map(i => [{ children: title(i.content), content: i.content }, []] as any)
    // ],
    children: title(nav.innovations)
  }), [nav.innovations, title]);

  // Support
  const support = useMemo<MenuItem>(() => ({
    content: nav.support,
    activeOn: ["support"],
    children: title(nav.support)
  }), [nav.support, title]);

  // News
  const news = useMemo<MenuItem>(() => ({
    content: nav.stories,
    activeOn: ["news", "newsPage"],
    children: title(nav.stories)
  }), [nav.stories, title]);

  // Exhibitions
  /* const exhibitions = useMemo<MenuItem>(() => ({
    content: nav.exhibitions,
    activeOn: ["exhibitions"],
    children: title(nav.exhibitions)
  }), [nav.exhibitions, title]); */

  // Sustainability
  const sustainability = useMemo<MenuItem>(() => nav.sustainability && {
    content: nav.sustainability,
    activeOn: ["sustainability"],
    children: title(nav.sustainability)
  }, [nav.sustainability, title]);

  // About Us
  const aboutUs = useMemo<MenuItem>(() => ({
    content: nav.aboutUs,
    activeOn: [
      "aboutUs",
      "job",
      "patents",
      "trademarks"
    ],
    dropdown: cms.locales.content.includes("en") ? [
      [{ content: nav.aboutUs, query: "#company", children: stringify(nav.aboutUs.values.title) }, []],
      [{ content: nav.aboutUs, query: "#eu", children: stringify(nav.aboutUs.values.madeInEUHeading) }, []],
      [{ content: nav.aboutUs, query: "#owners", children: stringify(nav.aboutUs.values.owners) }, []],
      [{ content: nav.aboutUs, query: "#history", children: stringify(nav.aboutUs.values.history) }, []],
      [{ content: nav.aboutUs, query: "#career", children: stringify(nav.aboutUs.values.career) }, []],
      [{ content: stringify(nav.aboutUs.values.careerExtLink), children: stringify(nav.aboutUs.values.dropdownCareerCz) }, []],
      [{ content: nav.aboutUs, query: "#legal", children: stringify(nav.aboutUs.values.legal) }, []]
    ] : [
      [{ content: nav.aboutUs, query: "#company", children: stringify(nav.aboutUs.values.title) }, []],
      [{ content: nav.aboutUs, query: "#eu", children: stringify(nav.aboutUs.values.madeInEUHeading) }, []],
      [{ content: nav.aboutUs, query: "#owners", children: stringify(nav.aboutUs.values.owners) }, []],
      [{ content: nav.aboutUs, query: "#history", children: stringify(nav.aboutUs.values.history) }, []],
      [{ content: nav.aboutUs, query: "#career", children: stringify(nav.aboutUs.values.career) }, []],
      [{ content: nav.aboutUs, query: "#legal", children: stringify(nav.aboutUs.values.legal) }, []]
    ],
    children: title(nav.aboutUs)

  }), [cms.locales.content, nav.aboutUs, stringify, title]);

  const defaultOffice = useLocalizedRelation("defaultContactPage", nav.offices.values, nav.offices.values.officeList![0]?.offices![0]);

  // Contacts
  const contacts = useMemo<MenuItem>(() => ({
    content: defaultOffice,
    activeOn: ["office"],
    withLabels: true,
    dropdown: nav.offices.values.officeList
      ?.filter(o => (o.offices?.length ?? [].length) > 0)
      .map(c => [
        { children: stringify(c.header), content: c.offices?.[0] },
        c.offices?.map(o => ({ children: title(o), content: o }))
      ]),
    children: title(nav.offices)
  }), [defaultOffice, nav.offices, stringify, title]);

  return {
    homepage,
    spotOn,
    products,
    innovations,
    support,
    news,
    sustainability,
    aboutUs,
    contacts
  };
};
