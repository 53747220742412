import { Link, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Box, Collapse, Flex, Icon, Link as UILink, Stack, Typography, useModal } from "@xcorejs/ui";
import DropIcon8 from "components/icons/8/drop.svg";
import { userLogout } from "components/Layout/LoggedBar";
import UserLoginModal from "components/UserLoginModal";
import { FC, useState } from "react";
import ScrollLock from "react-scrolllock";
import { useUser } from "utils/useUser";
import { useLayout } from "xcore";
import { AccountUpdate, Products, UserLoginPage } from "xcore/types";

import { HeaderState } from ".";
import NavItem, { SubNavLinkStyle } from "./NavItem";
import NavItems from "./NavItems";
import { useHeaderMenu } from "./useHeaderMenu";
import { useRevalidateCache } from "../../../pages/_app";
import { featuredItemsField } from "utils/products";

interface HeaderMiddleContentProps {
  state: HeaderState;
}

export const HeaderMiddleContent: FC<HeaderMiddleContentProps> = ({ state }) => {
  const { general, stringify, cms, header: { nav: { products } } } = useLayout();
  const [userDropdownActive, setUserDropdownActive] = useState(false);
  const { user: data } = useUser();
  const { data: userLoginPage } = useXcoreQuery(c => c.content.single<UserLoginPage>("userLoginPage"));
  const { data: accountUpdate } = useXcoreQuery(c => c.content.single<AccountUpdate>("accountUpdate"));
  const { data: productsData } = useXcoreQuery(c => state === "menu" ? c.content.single<Products>("products", v => [
    ...featuredItemsField(c.locales.content).map(f => v[f]?.$options(["title", ...featuredItemsField(c.locales.content)] as any)),
    ...featuredItemsField(c.locales.content).map(f => v[f]
      ?.map(r => featuredItemsField(c.locales.content).map(ff => r.values?.[ff]?.$options(featuredItemsField(c.locales.content) as any))))
  ]) : null);
  const revalidate = useRevalidateCache();

  const menu = useHeaderMenu(productsData?.content ?? products);
  const [open] = useModal(UserLoginModal);

  return (
    <ScrollLock isActive={state === "menu" || state === "localeSwitcher"}>
      <Flex
        alignItems="stretch"
        justifyContent={{ _: "space-between", md: "center" }}
        height={{ _: "calc((var(--vh, 1vh) * 100) - 6rem)", md: "9rem" }}
        position={{ _: "absolute", md: "static" }}
        top={{ _: "6rem", md: "0" }}
        left={{ _: "0", md: "0" }}
        background={{ _: "#fff", md: "transparent" }}
        flexDirection={{ _: "column", md: "row" }}
        width={{ _: "100vw", md: "auto" }}
        display={state !== "menu" ? { _: "none", md: "flex" } : { _: "flex" }}
        overflow={{ _: "auto", md: "unset" }}
      >
        <NavItems>
          <NavItem {...menu.homepage} />
          <NavItem {...menu.spotOn} />
          <NavItem {...menu.products} productDropdown />
          <NavItem {...menu.innovations} />
          <NavItem {...menu.support} />
          <NavItem {...menu.news} />
          {menu.sustainability && <NavItem {...menu.sustainability} />}
          <NavItem {...menu.aboutUs} />
          <NavItem {...menu.contacts} />
        </NavItems>

        {data ? (
          <Box display={{ _: "flex", md: "none" }} width="100%" position="relative">
            <Flex
              bg="lightGray"
              height="5rem"
              justifyContent="space-between"
              alignItems="center"
              px="2rem"
              width="100%"
              onClick={() => setUserDropdownActive(!userDropdownActive)}
            >
              <Flex>
                <Stack gap="0.4rem" alignItems="center">
                  <Typography color="black">
                    {data.company}
                  </Typography>
                </Stack>
              </Flex>

              <Flex alignItems="center">
                <Typography fontWeight={700} color="black" mx="0.8rem">
                  {data.firstName}{" "}{data.lastName}
                </Typography>

                <Icon
                  svg={<DropIcon8 />}
                  fill="#41414B"
                  transition="transform 300ms"
                  transform={userDropdownActive ? "rotate(-180deg)" : ""}
                />
              </Flex>
            </Flex>
            <Box
              bg="lightGray"
              position="absolute"
              bottom="5rem"
              width="100%"
            >
              <Collapse open={userDropdownActive}>
                <Link content={general.values.accountUpdate} {...SubNavLinkStyle} width="100%" justifyContent="center">
                  {accountUpdate && stringify(accountUpdate.content.values.title)}
                </Link>
                <UILink {...SubNavLinkStyle} justifyContent="center" onClick={userLogout(cms, revalidate)}>
                  Logout
                </UILink>
              </Collapse>
            </Box>
          </Box>
        ) : (
          <Flex bg="lightGray" height="5rem" display={{ _: "flex", md: "none" }}>
            <Typography
              display="flex"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              color="text"
              fontWeight={700}
              onClick={open}
            >
              {userLoginPage && stringify(userLoginPage.content.values.userLoginHeading)}
            </Typography>
          </Flex>
        )}
      </Flex>
    </ScrollLock>
  );
};
