import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Box, Collapse, Flex, Icon, Text } from "@xcorejs/ui";
import GlobeIcon from "components/icons/16/globe.svg";
import DropIcon8 from "components/icons/8/drop.svg";
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { useLayout } from "xcore";

import { ActiveLanguageStyle } from "./DomainSwitcher";
import { ActiveNavLinkStyle, SubNavLinkStyle } from "./NavItem";
import { useDomainSwitcher } from "./useDomainSwitcher";
import { MOBILE_DOMAIN_SWITCHER_LOCALES_ABBREVIATIONS } from "utils/useLocalizedRelation";

interface Props {
  open: boolean;
  setState: (o: boolean) => unknown;
}

const MobileDomainSwitcher: FC<Props> = ({ open, setState }) => {
  const { domain } = useLayout();
  const [ssrHide, setSsrHide] = useState(true);
  const { locale } = useRouter();
  const { dropdownLinkProps } = useDomainSwitcher();

  useEffect(() => void setSsrHide(false), []);

  return (
    <Flex
      display={{ _: "flex", md: "none" }}
      ml="1.5rem"
      onClick={() => setState(!open)}
    >
      <Box position="relative" display="flex" alignItems="center">
        <Icon svg={<GlobeIcon />} fill={open ? "robe" : "gray"} mr="0.5rem" height="1.7rem" />

        <Text
          width="100%"
          color={open ? "black" : "#939598"}
          fontSize="1.4rem"
          fontWeight={600}
        >
          {formatLocale(locale)}
        </Text>

        <Icon
          svg={<DropIcon8 />}
          fill={open ? "black" : "#939598"}
          transition="transform 300ms"
          ml="0.65rem"
          transform={open ? "rotate(180deg)" : "none"}
        />
      </Box>

      {!ssrHide && (
        <Collapse
          open={open}
          position="absolute"
          left="0"
          top="6rem"
          width="100%"
          background="#fff"
          height="calc(100vh - 6rem)"
          padding="0.5rem 2rem"
        >
          {dropdownLinkProps.map(({ locale: l, ...p }, i) => (
            <Link
              key={i}
              {...p}
              type="simple"
              position="relative"
              width="100%"
              justifyContent="space-between"
              {...SubNavLinkStyle}
            >
              <Text lineHeight="2.1rem" {...p.domain === domain && { ...ActiveLanguageStyle }}>{p.label}</Text>
              <Text fontWeight={600} lineHeight="2.1rem" {...p.domain === domain && { ...ActiveNavLinkStyle }}>
                {l.split("-")[0].toUpperCase()}
              </Text>
            </Link>
          ))}
        </Collapse>
      )}
    </Flex>
  );
};

const formatLocale = (locale: string) => MOBILE_DOMAIN_SWITCHER_LOCALES_ABBREVIATIONS.hasOwnProperty(locale)
  ? MOBILE_DOMAIN_SWITCHER_LOCALES_ABBREVIATIONS[locale]
  : locale.split("-")[0].toUpperCase();

export default MobileDomainSwitcher;
