import { useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Box, Container, Icon, Link, SimpleGrid, Stack } from "@xcorejs/ui";
import SVGInstagram from "components/icons/32/instagram.svg";
import InstaCard from "design-system/robe/cards/Insta";
import { FC } from "react";
import { useLayout } from "xcore";
import RobeRichtext from "./RobeRichtext";
import { useRouter } from "next/router";
import { resolveFetchTimeout } from "utils/resolveFetchTimeout";

type InstagramResponse =
  | { status: "failed" }
  | { status: "success"; posts: InstagramPost[] };

interface InstagramPost {
  permalink: string;
  imageUrl: string;
}

const Instagram: FC = () => {
  const { general } = useLayout();
  const { locale } = useRouter();
  const { data } = useXcoreQuery<InstagramResponse>(cms => cms.fetch("/api/instagram", undefined, resolveFetchTimeout(locale))
    .then(r => r.json()));

  if (data?.status === "failed" || !data?.posts) return null;

  return (
    <Box pt={{ _: "5rem", sm: "6rem" }} bg="black">
      <Container mb={{ _: "5rem", sm: "6rem" }} flexDirection="column">
        <Stack direction="column" gap="4rem" align="center">
          <Link variant="simple" href="https://www.instagram.com/robelighting/" target="_blank">
            <Icon svg={<SVGInstagram />} />
          </Link>

          <Box>
            <RobeRichtext
              value={general.values.aboveFooter}
              _link={{
                variant: "underline",
                fontSize: "inherit",
                lineHeight: "inherit"
              }}
              _paragraph={{
                variant: "lead",
                textAlign: "center"
              }}
            />
          </Box>
        </Stack>
      </Container>

      <SimpleGrid columns={{ _: 2, sm: 6 }} gap="0">
        <PostGrid
          items={data.posts.slice(0, 6)}
        />
      </SimpleGrid>
    </Box>
  );
};

const PostGrid: React.FC<{ items: InstagramPost[] }> = ({ items }) => (
  <>
    {items.map((item, i) => {
      return (
        <InstaCard
          key={i}
          image={item.imageUrl}
          href={item.permalink}
          alt="Instagram Photo"
        />
      );
    })}
  </>
);

export default Instagram;
