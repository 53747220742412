import { Stack, useTheme } from "@xcorejs/ui";
import { cloneElement, Dispatch, FC, ReactElement, useRef, useState } from "react";
import { useMediaQuery } from "utils/useMediaQuery";

import NavItem, { NavItemProps } from "./NavItem";

interface Props {
  children: ReactElement<NavItemProps & { open: boolean; setOpen: Dispatch<boolean> }, typeof NavItem>[];
}

const NavItems: FC<Props> = ({ children }: Props): ReturnType<FC> => {
  const [state, setState] = useState<number>();
  const delayedState = useRef<NodeJS.Timeout>();
  const canceled = useRef<boolean>(false);
  const theme = useTheme();
  const [active] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const removeState = () => {
    canceled.current = false;
    setTimeout(() => {
      !canceled.current && setState(undefined);
    }, 400);
  };

  return (
    <Stack
      onMouseLeave={removeState}
      direction={{ _: "column", md: "row" }}
      gap={{ _: "0", md: "1.5rem", lg: "2rem" }}
      pt={{ _: "2rem", md: 0 }}
      flex={{ _: "0 0 auto" }}
    >
      {children.filter(Boolean).map((nav, i) =>
        cloneElement(nav, {
          key: i,
          view: !active ? "dropdown" : "accordion",
          setOpen: (s: boolean) => {
            if (s) {
              canceled.current = true;
              setState(i);
            } else {
              canceled.current = false;
              removeState();
            }
          },
          delayedState: delayedState,
          open: state === i,
          isAnythingOpen: !!state
        }))}
    </Stack>
  );
};

export default NavItems;
